.trigger {
    /*padding-top: 24px;*/
    padding-left: 24px;
    padding-right: 14px;
    vertical-align: middle;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    float: left;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }

  .activePageTitle {
    float: left;
    padding-top: 0px;
    padding-left: 0px;
    font-size: 20px;
    font-weight: bold;
    line-height: 64px;
  }

  .login-form {
    max-width: 500px;
    min-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
  .login-form-title {
    font-size: 20px;
    font-weight: bold;
  }
  .app-title {
    font-size: 17px;
    font-weight: bold;
    color:cornsilk;
    padding-top: 18px;
    padding-bottom: 15px;
    text-align: center;
  }